<template>
  <div>
    <van-tabs swipeable>
      <van-tab
        title="平安家庭财产保险（火险）"
      >
        <sinopec-infectious></sinopec-infectious>
      </van-tab>
      <van-tab title="平安附加居家责任保险">
        <sinopec-critically></sinopec-critically>
      </van-tab>
      <van-tab title="平安法定产染病身故保险">
        <sinopec-disease></sinopec-disease>
      </van-tab>
    </van-tabs>
    <bottom-button></bottom-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class Disclaimer extends Vue {
   static name = "Disclaimer";
}
export default Disclaimer;
</script>
<style lang="scss" src="../index.scss" scoped></style>
